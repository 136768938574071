<template>

  <div v-if="data.role_name && data_statistics && data_statistics.stats" class="bx">

      <!--  -->

      <div class="wbxhr"></div>

      <!--  -->

      <div class="wbx">
        <img class="power_img" :src="data_statistics.ling_images + 'power.jpg'" alt="">

        <h1>
          Advertising Performance {{ data_statistics.month }} {{ data_statistics.year }}
        </h1>
        <p class="hnt">
          {{ data_statistics.package_name }} plan &#8226; {{ data_statistics.name }}
        </p>
      </div>

      <!--  -->

      <div class="wbxhr"></div>

      <!--  -->
      <div class="wbx">   

        <h2>
          Banners results
        </h2>
        <p class="hnt">
          Your banners had more clicks than <span class="bold_color">{{ getRandomInt(5,15) }}%</span> of other Grow Diaries Advertisers
        </p>

        <div class="stats">

          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'banner.jpg'" alt="">
              New Banners
            </div>
            <div class="vl">
              {{ data_statistics.stats.cnt_new_box_baners + data_statistics.stats.cnt_new_cpm_baners + data_statistics.stats.cnt_new_header_baners }}
            </div>
          </div>
          
          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'eye.jpg'" alt="">
               Ad Impressions
            </div>
            <div class="vl">
              {{ data_statistics.stats.total_views }}
            </div>
          </div>

        </div>

        <div class="shr"></div>
        <div class="shrhint">
          {{ data_statistics.banners_tip }}
        </div>

        <br />
        
        <table border="0" cellpadding="0" cellspacing="0" class="btn_b2b btn-primary-b2b" v-if="false">
          <tbody>
            <tr>
              <td align="center">
                <table border="0" cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td> <span>More info</span> </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <!--  -->

      <div class="wbxhr"></div>

      <!--  -->

      <div class="wbx">   

        <div v-if="data_statistics.is_platinum || data_statistics.is_platinum_plus">
          <h2>
            Community Work Results
          </h2>
          <p class="hnt">
              <span v-if="data_statistics.stats.chat_replied_per<=0">This month you have no messages from the growers, but there is an opportunity to launch a new promotion for them.</span>
              <span v-else>Your have replied to <span class="bold_color">{{ data_statistics.stats.chat_replied_per.toFixed(2) }}%</span> of growers’ messages this month</span>
          </p>

          <div class="stats">

            <div class="stat">
              <div class="nm">
                <img class="ic" :src="data_statistics.ling_images + 'users.jpg'" alt="">
                New Subscribers
              </div>
              <div class="vl">
                {{ data_statistics.stats.new_subscribers }}
              </div>
            </div>
            
            <div class="stat">
              <div class="nm">
                <img class="ic" :src="data_statistics.ling_images + 'comment.jpg'" alt="">
                Brand Mentions
              </div>
              <div class="vl">
                {{ data_statistics.stats.cnt_mentions }}
              </div>
            </div>

          </div>

          <div class="shr"></div>
          <div class="shrhint">
            {{ data_statistics.work_tip }}
          </div>

          <br />
          
          <table border="0" cellpadding="0" cellspacing="0" class="btn_b2b btn-primary-b2b" v-if="false">
            <tbody>
              <tr>
                <td align="center">
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td> <span>More info</span> </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div v-else>

          <h2>
            Community Work Results
          </h2>
          <p class="hnt">
              <span v-if="data_statistics.stats.chat_replied_per<=0">This month you have no messages from the growers, but there is an opportunity to launch a new promotion for them.</span>
              <span v-else>Your have replied to <span class="bold_color">{{ data_statistics.stats.chat_replied_per.toFixed(2) }}%</span> of growers’ messages this month</span>
          </p>

          <div class="stats">

            <div class="stat">
              <div class="nm">
                <img class="ic" :src="data_statistics.ling_images + 'users.jpg'" alt="">
                New Subscribers
              </div>
              <div class="vl">
                {{ data_statistics.stats.new_subscribers }}
              </div>
            </div>
            
            <div class="stat">
              <div class="nm">
                <img class="ic" :src="data_statistics.ling_images + 'comment.jpg'" alt="">
                New Diaries
              </div>
              <div class="vl">
                {{ data_statistics.stats.new_reports }}
              </div>
            </div>

          </div>

          <div class="shr"></div>
          <div class="shrhint">
            {{ data_statistics.work_tip }}
          </div>

          <br />
          
          <table border="0" cellpadding="0" cellspacing="0" class="btn_b2b btn-primary-b2b" v-if="false">
            <tbody>
              <tr>
                <td align="center">
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td> <span>More info</span> </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>

      <!--  -->

      <div class="wbxhr"></div>

      <!--  -->

      <div class="wbx">   

        <div v-if="data_statistics.is_trial" class="stats">

          <div class="lf">
            
            <h2 class="h2act">
            How to Improve
            </h2>
            <p class="hntact">
              Results of annual  advertising campaigns of Lite plan
            </p>
            
          </div>
          
          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'eye.jpg'" alt="">
              Ad Impressions
            </div>
            <div class="vl">
              + 4 500 000
            </div>
          </div>

        </div>
        <div v-else-if="data_statistics.is_lite" class="stats">

          <div class="lf">
            
            <h2 class="h2act">
            How to Improve
            </h2>
            <p class="hntact">
              Results of annual  advertising campaigns of Advanced plan
            </p>
            
          </div>
          
          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'eye.jpg'" alt="">
              Ad Impressions
            </div>
            <div class="vl">
              + 4 700 000
            </div>
          </div>

          <p>New tools for communicating with growers that increase company rankings and clicks: Contest, Article, Photos & Videos Tab</p>

        </div>
        <div v-else-if="data_statistics.is_advanced" class="stats">

          <div class="lf">
            
            <h2 class="h2act">
            How to Improve
            </h2>
            <p class="hntact">
              Results of annual  advertising campaigns of Platinum plan
            </p>
            
          </div>
          
          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'eye.jpg'" alt="">
              Ad Impressions
            </div>
            <div class="vls">
              + 14 000 000
            </div>
          </div>

          <p>
    Unlock HOT Season
    Unlimited number of contest.
    Newsletters all year covered
    Double impressions on box banners
    SEO article</p>

        </div>
        <div v-else-if="data_statistics.is_platinum" class="stats">

          <div class="lf">
            
            <h2 class="h2act">
            How to Improve
            </h2>
            <p class="hntact">
              Results of annual  advertising campaigns of Platinum+ plan
            </p>
            
          </div>
          
          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'eye.jpg'" alt="">
              Ad Impressions
            </div>
            <div class="vls">
              + 14 000 000
            </div>
          </div>

          <p>Main Page Header Banners 12 months covered</p>

        </div>
        <div v-else-if="data_statistics.is_platinum_plus" class="stats">

          <div class="lf">
            
            <h2 class="h2act">
            How to Improve
            </h2>
            <p class="hntact">
              Upgrade to Full Header Banners
            </p>
            
          </div>
          
          <div class="stat">
            <div class="nm">
              <img class="ic" :src="data_statistics.ling_images + 'eye.jpg'" alt="">
              Ad Impressions
            </div>
            <div class="vls">
              + 60 000 000
            </div>
          </div>

          <p>ALL Header Banners 12 months covered</p>

        </div>

        <br>

        <table border="0" cellpadding="0" cellspacing="0" class="btn_b2b btn-primary-b2b">
          <tbody>
            <tr>
              <td align="center">
                <table border="0" cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td v-if="false"> <a href="https://growdiaries.com/partner" target="_blank">Upgrade to Lite</a> </td>

                      <td v-if="data_statistics.is_trial"> <a href="https://growdiaries.com/partner" target="_blank">Upgrade to Lite</a> </td>
                      <td v-else-if="data_statistics.is_lite"> <a href="https://growdiaries.com/partner" target="_blank">Upgrade to Advanced</a> </td>
                      <td v-else-if="data_statistics.is_advanced"> <a href="https://growdiaries.com/partner" target="_blank">Upgrade to Platinum</a> </td>
                      <td v-else-if="data_statistics.is_platinum"> <a href="https://growdiaries.com/partner" target="_blank">Upgrade to Platinum Plus</a> </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

     

      </div>

      <!--  -->

      <div class="wbxhr"></div>

      <!--  -->


    </div>
  
</template>

<script setup>

const {$api} = useNuxtApp()

const props = defineProps({
  data: {
    type: Object    
  }
});

const loadStatistics = () => {
  $api.getB2BStatistics().then(res => {
    if(res){
      data_statistics.value = res;
      return data_statistics
    }
  })
}

const { pending: is_loading,  data: data_statistics } = await useLazyAsyncData('data_statistics', async () => await loadStatistics())

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

/*
export default { 
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {   
      data_statistics: {}
    }
  },
  created() {  
    this.loadStatistics();  
  },
  methods: {   
    loadStatistics(){
      this.$axios.get('/brands-manager/statistics', {
        params: {
          
        }
      })
      .then((response) => { 
        if(response.status === 200){                               
          data_statistics = response.data.data;
        }      
      })
      .catch(function (error) { 
        
      });
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    }
  }
}*/
</script>

<style>



/**/
 
</style>

<style scoped>
 
.bx{
  background-color: whitesmoke;
  /* padding: 20px; */
  /* width: 100%; */
}

.wbx{
  background-color: white;
  padding: 30px;
  margin-left: 20px;
  margin-right: 20px;
  /* width: 100%; */
}
.wbxhr{
  height: 20px;
}
.power_img{
  width: 240px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
h1{
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}
h2{
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}
.hnt{
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}

.bold_color{
  color: #3c70f6;
}

/* STAT */

.stats{
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.stat{
  display: inline-block;
  width: 40%;
  text-align: center;
  vertical-align: top;
}
.stat .nm{
  font-size: 15px;
}
.stat .nm .ic{
  width: 20px;
  vertical-align: sub;
}
.stat .vl{
  color: #3c70f6;
  font-weight: bold;
  font-size: 40px;
}

.stat .vls{
  color: #3c70f6;
  font-weight: bold;
  font-size: 30px;
}
.shr{
  width: 100%;height: 1px;background-color: gray;margin-top: 15px;
}

.shrhint{
  color: gray;
  text-align: center;
  margin-top: 20px;
}

/* ACT */


.lf{
  display: inline-block;
  width: 50%; 
  text-align: left;
}

.h2act{
  text-align: left;
}

.hntact{
  font-size: 17px;
  font-weight: bold; 
  margin-bottom: 0;
  text-align: left;
}


/* -------------------------------------
    BUTTONS
------------------------------------- */
.btn_b2b {
  box-sizing: border-box;
  width: 100%; }
  .btn_b2b > tbody > tr > td {
    padding-bottom: 15px; }
  .btn_b2b table {
    width: auto; }
  .btn_b2b table td {
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center; }
  .btn_b2b a {
    background-color: #ffffff;
    border: solid 1px #a2da7a;
    border-radius: 5px;
    box-sizing: border-box;
    color: #a2da7a;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 12px 25px;
    text-decoration: none;
    text-transform: capitalize; }
  .btn_b2b span {
    background-color: #ffffff;
    border: solid 1px #a2da7a;
    border-radius: 5px;
    box-sizing: border-box;
    color: #a2da7a;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 12px 25px;
    text-decoration: none;
    text-transform: capitalize; }


.btn-primary table td {
  background-color: #a2da7a; }

.btn-primary a {
  background-color: #a2da7a;
  border-color: #a2da7a;
  color: #ffffff; }

.btn-primary-b2b {
  width: 100%; }
  
.btn-primary-b2b table td {
  background-color: #3c70f6; }

.btn-primary-b2b a {
  background-color: #3c70f6;
  border-color: #3c70f6;
  color: #ffffff; }

.btn-primary-b2b span {
  background-color: #3c70f6;
  border-color: #3c70f6;
  color: #ffffff; }
  
.btn-white table td {
  background-color: transparent; }

.btn-white a {
  background-color: #ffffff;
  color: #a2da7a; }



.btn-white.basic table td {
  background-color: transparent; }

.btn-white.basic a {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff; }




@media only screen and (max-width: 620px) {
    
  h1{
    font-size: 18px; 
    line-height: 25px;
  }
  h2{
    font-size: 18px; 
  }
  .hnt{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
  }

  .lf{    
    width: 100%; 
    text-align: center;
    margin-bottom: 20px;
  }
  

  .h2act{
    text-align: center;
  }

  .hntact{
    font-size: 15px; 
    text-align: center;
  }

  .stat{ 
    width: 100%; 
  }
}

</style>